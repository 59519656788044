body,
html,
div#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

div#root {
  display: flex;
  align-items: center;
  justify-content: center;
}
